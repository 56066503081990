@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap');

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre SemiBold';
  src: url('./assets/fonts/calibre-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Cirular';
  src: url('./assets/fonts/circular-medium.ttf') format('truetype');
}

.html,
body {
  margin: 0;
  padding: 0;
  background-color: #292a2e;
  // background-image: linear-gradient(to bottom right, #222, #111);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Circular', sans-serif;
}

.Login {
  text-align: center;
  color: white;

  h1 {
    font-weight: bold;
    font-size: 4rem;
    margin: 5px 0;
  }

  button {
    background-color: #1db954;
    padding: 10px 30px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    border: none;
    color: white;
    font-size: 1rem;
    outline: none;

    &:hover,
    &:active {
      background-color: lighten(#1db954, 10);
    }
  }
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#main-content {
  width: 100%;
  // max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.right {
  width: 40%;
}

.mockup-container {
  height: 92vh;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #404040;
  border-left: 1px solid #1e2022;

  .mockup {
    height: 70vh;
    width: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 48px;
    padding: 60px 8px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      margin-left: -80px;
      top: 22px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #333;
    }

    &:after {
      content: '';
      position: absolute;
      top: 25px;
      height: 5px;
      width: 80px;
      border-radius: 60px;
      background-color: #333;
    }

    .phone {
      height: 100%;
      width: 100%;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      border-radius: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .top {
        img {
          display: block;
          width: 100%;
        }
      }

      .bottom {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #333;
        // box-shadow: 0 -3px 6px rgba(0,0,0,0.16), 0 -3px 6px rgba(0,0,0,0.23);
        box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12),
          0 -1px 2px rgba(0, 0, 0, 0.24);
        transition: background-color 0.5s ease-in-out;

        .spacer {
          flex: 1;
        }

        .next {
          flex: 1;
          min-height: 40px;
          background-color: rgba(0, 0, 0, 0.2);
          color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;

          .left {
            span {
              padding-left: 20px;
            }
          }
        }

        .time {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          padding: 0 20px;
          margin-top: 20px;
          font-size: 0.75rem;

          .line {
            width: 100%;
            padding: 0 10px;
            position: relative;

            span {
              display: block;
              width: 100%;
              height: 2px;
              background-color: rgba(255, 255, 255, 0.5);

              &:nth-child(2) {
                position: absolute;
                background-color: white;
                margin-top: -2px;
                width: 30%;

                &:after {
                  content: '';
                  display: block;
                  height: 12px;
                  width: 12px;
                  border-radius: 50%;
                  background-color: white;
                  position: absolute;
                  right: 0;
                  margin-top: -5px;
                }
              }
            }
          }
        }

        .controls {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.5rem;
          margin: 0px 20px;
          color: white;

          #play {
            border: 4px solid white;
            border-radius: 50%;
            height: 80px;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          margin: 20px 0;
          text-align: center;

          .artists {
            // margin-top: -16px;
          }

          .shadow {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
            transition: box-shadow 0.5s ease-in-out;
          }

          .marquee {
            font-size: 1.5rem;
            margin: 0 auto;
            color: white;
            white-space: nowrap;
          }

          span {
            text-align: center;
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }
}

#search {
  height: 8vh;

  .songs {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #111;

    .search-bar {
      height: 100%;
      width: 100%;
      // max-width: 1024px;
      display: flex;
      align-items: center;

      input {
        background-color: transparent;
        border: none;
        height: 100%;
        flex: 1;
        padding: 0px;
        outline: none;
        color: #ddd;
        font-size: 2rem;
        border-radius: 3px;
        text-indent: 20px;
      }

      #mag-glass {
        height: 40px;
        width: 40px;
        padding: 0 10px;
        filter: invert(1) brightness(0.5);
      }
    }

    .results {
      position: absolute;
      z-index: 99;
      top: 8vh;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #000;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

      .tracks {
        width: 100%;
        max-width: 1024px;
        overflow: auto;
        transition: height 0.2s ease-in-out;

        .track {
          display: flex;
          margin-top: 5px;
          line-height: 32px;
          font-size: 1rem;
          color: #ddd;
          cursor: pointer;
          border-radius: 3px;

          &:hover {
            background-color: #111;
          }

          &-album-image {
            min-width: 64px;
            background-repeat: no-repeat;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }

          &-info {
            padding-left: 10px;
            height: 100%;
          }

          &-artist {
            display: flex;
            flex-wrap: wrap;
            font-size: 0.8rem;
            padding: 5px 0;
            margin: 0;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.swatches {
  display: flex;
  flex-wrap: wrap;

  .color {
    height: 100px;
    width: 100px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #main-content {
    flex-direction: column-reverse;

    .mockup-container {
      height: 100vh;
      border: none;
    }

    .right {
      width: 100%;
    }

    .mockup {
      padding: 0;
      height: 100vh;
      width: 100vw;

      .phone {
        box-shadow: none;
      }

      &:before,
      &:after {
        display: none;
      }
    }
  }

  .mockup-container {
    width: 100%;
  }
}
